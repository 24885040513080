import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import {
  standardColours,
  brandColours,
  brandFonts,
  fontWeights,
  fontSize,
} from '../styles/utilities';
import { Button } from './ui';

const StyledCompanyRequestFormField = styled.label`
  display: block;
  margin-bottom: 15px;
`;

const StyledCompanyRequestFormText = styled.span`
  display: block;
  margin-bottom: 10px;
  ${fontSize(15)};
`;

const StyledCompanyRequestFormInput = styled.input`
  padding: 10px 20px;
  height: 50px;
  width: 100%;
  color: ${brandColours.secondary};
  font-family: ${brandFonts.primary};
  font-weight: ${fontWeights.regular};
  ${fontSize(16)};
  background-color: ${standardColours.white};
  border: 1px solid ${brandColours.secondary};
  border-radius: 10px;
`;

const handleSubmit = e => {
  e.preventDefault();

  fetch('/.netlify/functions/submitCompanyRequest', {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    //make sure to serialize your JSON body
    body: JSON.stringify({
      company: e.target.company.value,
    }),
  })
    .then(response => response.json())
    .then(console.log);

  navigate('/search');
};

const CompanyRequestForm = () => {
  return (
    <form onSubmit={e => handleSubmit(e)}>
      <StyledCompanyRequestFormField htmlFor="company">
        <StyledCompanyRequestFormText>
          Name of company
        </StyledCompanyRequestFormText>
        <StyledCompanyRequestFormInput
          type="text"
          id="company"
          name="company"
        />
      </StyledCompanyRequestFormField>
      <Button>Submit</Button>
    </form>
  );
};

export default CompanyRequestForm;
