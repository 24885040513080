import React from 'react';
import { Helmet } from 'react-helmet';
import { Heading, Text } from '../components/ui';
import Layout from '../components/Layout';
import CompanyRequestForm from '../components/CompanyRequestForm';

const CompanyNotFound = () => (
  <Layout>
    <Helmet title="Company Not Found | Impact Score" defer={false} />
    <Heading>Company Not Found?</Heading>
    <Text>Please request data for a company using the form below</Text>
    <CompanyRequestForm />
  </Layout>
);

export default CompanyNotFound;
